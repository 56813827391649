import { action, makeObservable, observable } from "mobx";
import { createClient } from "contentful";

const client = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID,
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID
});

const fetchPage = async (blogPostId) => {
  const response = await client.getEntry(blogPostId);
  return response;
};

export class PageStore {
  page = null;

  constructor() {
    makeObservable(this, {
      page: observable,
      setPageAsNull: action
    });
  }

  fetchPage = async (id) => {
    const response = await fetchPage(id);
    const data = await response;

    this.page = {
      id: data.sys.id,
      headline: data.fields.headline,
      body: data.fields.pageBodyCopy,
      mainImageId: data.fields.mainMedia?.sys?.id
    };
  };

  setPageAsNull = () => {
    this.page = null;
  };
}
