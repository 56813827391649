import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useParams } from "react-router-dom";

import { Loading } from "@namroodinc/ni-ui";

import ContentDisplay from "../contentDisplay";
import { useStore } from "../../stores";

const BlogPost = observer(() => {
  const blogPostStore = useStore("blogPostStore");
  const { blogPost } = blogPostStore;
  const { id } = useParams();

  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant" // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      blogPostStore.fetchBlogPost(id);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [blogPostStore, id]);

  useEffect(() => {
    return () => {
      blogPostStore.resetBlogPost();
    };
  }, [blogPostStore]);

  if (!blogPost) {
    return <Loading />;
  }

  return <ContentDisplay {...blogPost} />;
});

BlogPost.propTypes = {};

BlogPost.defaultProps = {};

export default BlogPost;
