import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { Button, Card, Columns, Loading } from "@namroodinc/ni-ui";

import { useStore } from "../../stores";
import Asset from "../asset";

import styles from "./styles.module.scss";

const BlogPostList = observer(() => {
  const blogPostStore = useStore("blogPostStore");
  const { tag } = useParams();

  useEffect(() => {
    blogPostStore.resetBlogPostList();

    const timeout = setTimeout(() => {
      blogPostStore.fetchBlogPostList(tag ? [tag] : []);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [blogPostStore, tag]);

  if (blogPostStore.blogPostListLoading) {
    return <Loading />;
  }

  return (
    <>
      {blogPostStore.getTag?.label ? (
        <Helmet>
          <title>
            Posts tagged with "{blogPostStore.getTag.label}" |{" "}
            {process.env.REACT_APP_MY_WEBSITE_NAME}
          </title>
        </Helmet>
      ) : (
        <Helmet>
          <title>Home | {process.env.REACT_APP_MY_WEBSITE_NAME}</title>
        </Helmet>
      )}

      <div className={styles.blogPostList}>
        <Columns numberOfColumns={1}>
          {blogPostStore.getTag?.label && (
            <h1>Posts tagged with "{blogPostStore.getTag.label}"</h1>
          )}
          {blogPostStore.getTag?.description && (
            <span className={styles.blogPostListDescription}>
              {blogPostStore.getTag.description}
            </span>
          )}
          <Columns numberOfColumns={3}>
            {blogPostStore.blogPostList.map((post) => {
              return (
                <Card
                  key={post.id}
                  date={post.createdAt}
                  fadeInUp
                  link={
                    <Button
                      buttonType="button"
                      element={Link}
                      label="Read More"
                      to={`/post/${post.id}`}
                      size="medium"
                    />
                  }
                  mainImage={
                    <Link to={`/post/${post.id}`}>
                      <Asset imageId={post.mainImageId} />
                    </Link>
                  }
                  profiling="shadow"
                  {...post}
                  headerProps={{
                    heading: (
                      <Link to={`/post/${post.id}`}>{post.headline}</Link>
                    )
                  }}
                />
              );
            })}
          </Columns>
          {blogPostStore.showMore && (
            <Button
              label="Load More"
              onClick={() => blogPostStore.fetchBlogPostList(tag ? [tag] : [])}
              width="full"
            />
          )}
        </Columns>
      </div>
    </>
  );
});

BlogPostList.propTypes = {};

BlogPostList.defaultProps = {};

export default BlogPostList;
