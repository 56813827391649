import { BrowserRouter, Routes, Route } from "react-router-dom";
import posthog from "posthog-js";

import BlogPost from "./components/blogPost";
import Home from "./components/home";
import Layout from "./components/layout";
import Page from "./components/page";
import { StoresProvider, stores } from "./stores";

import { NiUiProvider } from "@namroodinc/ni-ui";

function App() {
  if (process.env.NODE_ENV === "production") {
    posthog.init(process.env.REACT_APP_POST_HOG_CODE, {
      api_host: "https://eu.posthog.com"
    });
  }

  const initialConfig = {
    email: process.env.REACT_APP_MY_EMAIL,
    flagsake: `https://www.flagsake.com`,
    github: process.env.REACT_APP_MY_GITHUB,
    linkedIn: process.env.REACT_APP_MY_LINKEDIN,
    name: process.env.REACT_APP_MY_NAME,
    npm: process.env.REACT_APP_MY_NPM,
    theme: localStorage.getItem("theme") || "light",
    twitter: process.env.REACT_APP_MY_TWITTER,
    siteName: "namrood,inc."
  };

  return (
    <BrowserRouter>
      <NiUiProvider initialConfig={initialConfig}>
        <StoresProvider value={stores}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="posts/:tag" element={<Home />} />
              <Route
                path="about"
                element={<Page id="35UdcXAsSsugQUqQ2oY0Ym" />}
              />
              <Route
                path="experience"
                element={<Page id="5BUeqawMF2DRkzJUJ0K7Jd" />}
              />
              <Route path="post/:id" element={<BlogPost />} />
            </Route>
          </Routes>
        </StoresProvider>
      </NiUiProvider>
    </BrowserRouter>
  );
}

export default App;
