import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import { useStore } from "../../stores";
import { Image } from "@namroodinc/ni-ui";

const Asset = observer(({ addToMeta, imageId, width }) => {
  const assetsStore = useStore("assetsStore");
  const image = assetsStore.assets.find((asset) => asset.id === imageId);
  return (
    image && (
      <>
        {addToMeta && (
          <Helmet>
            <meta
              property="og:image"
              content={`${image.file.url}?w=${width}`}
            />
            <meta
              name="twitter:image"
              content={`${image.file.url}?w=${width}`}
            />
          </Helmet>
        )}
        <Image
          src={`${image.file.url}?w=${width}`}
          alt={
            image.description ||
            "Description to come, please contact me for any assets without descriptions"
          }
        />
      </>
    )
  );
});

Asset.propTypes = {
  addToMeta: PropTypes.bool,
  imageId: PropTypes.string,
  width: PropTypes.number
};

Asset.defaultProps = {
  addToMeta: false,
  imageId: "",
  width: 500
};

export default Asset;
