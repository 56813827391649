import React from "react";
import { observer } from "mobx-react-lite";

import styles from "./styles.module.scss";
import { Button, useNiUi } from "@namroodinc/ni-ui";

const Socials = observer(() => {
  const { config } = useNiUi();

  return (
    <div className={styles.socials}>
      <h6>Socials / Other Links</h6>
      <Button
        buttonType="anchor"
        className={styles.socialsButtonLinkedIn}
        element="a"
        label="LinkedIn"
        path={`${config.linkedIn}`}
        size="small"
        target="_blank"
        rel="noreferrer"
      />
      <Button
        buttonType="anchor"
        className={styles.socialsButtonGithub}
        element="a"
        label="GitHub"
        path={`${config.github}`}
        size="small"
        target="_blank"
        rel="noreferrer"
      />
      <Button
        buttonType="anchor"
        className={styles.socialsButtonTwitter}
        element="a"
        label="Twitter"
        path={`${config.twitter}`}
        size="small"
        target="_blank"
        rel="noreferrer"
      />
      <Button
        buttonType="anchor"
        className={styles.socialsButtonNPM}
        element="a"
        label="NPM"
        path={`${config.npm}`}
        size="small"
        target="_blank"
        rel="noreferrer"
      />
      <Button
        buttonType="anchor"
        element="a"
        label="Flagsake.com"
        path={`${config.flagsake}`}
        size="small"
        target="_blank"
        rel="noreferrer"
      />
      <Button
        buttonType="anchor"
        element="a"
        label="Email"
        path={`mailto:${config.email}`}
        size="small"
      />
    </div>
  );
});

export default Socials;
