import React, { useEffect } from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import {
  Button,
  Code,
  Columns,
  Modal,
  Tags,
  Topper,
  useNiUi
} from "@namroodinc/ni-ui";
import {
  MMAHeadToHead,
  PlayingArea,
  SportsCard
} from "@namroodinc/pitchlab-ui";

import Asset from "../asset";

import styles from "./styles.module.scss";

const ContentDisplay = (content) => {
  const { setScrollDepth } = useNiUi();

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <Asset imageId={node.data.target.sys.id} />
      ),
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const { sys } = node.data.target;
        return <Link to={`/post/${sys.id}`}>{node.content[0].value}</Link>;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const { fields, sys } = node.data.target;

        // todo: refactor this bit
        switch (sys.contentType.sys.id) {
          case "pitchLab":
            switch (fields.pitchLabComponent["0"]) {
              case "playingArea":
                return (
                  <>
                    <h4>{fields.headline}</h4>
                    {documentToReactComponents(fields.copy, options)}
                    <PlayingArea
                      sport={fields.sport["0"]}
                      {...fields.pitchLabComponentProps}
                      {...fields.data}
                    />
                    <Modal openModalButtonLabel={fields.openModalLabelForData}>
                      <Code>{JSON.stringify(fields.data, null, 2)}</Code>
                    </Modal>
                  </>
                );
              case "sportsCard":
                return (
                  <>
                    <h4>{fields.headline}</h4>
                    {documentToReactComponents(fields.copy, options)}
                    <SportsCard
                      displayPicSize={"large"}
                      sport={fields.sport["0"]}
                      {...fields.pitchLabComponentProps}
                      {...fields.data}
                    />
                    <Modal openModalButtonLabel={fields.openModalLabelForData}>
                      <Code>{JSON.stringify(fields.data, null, 2)}</Code>
                    </Modal>
                  </>
                );
              case "mmaHeadToHead":
                return (
                  <>
                    <h4>{fields.headline}</h4>
                    {documentToReactComponents(fields.copy, options)}
                    <MMAHeadToHead
                      {...fields.pitchLabComponentProps}
                      {...fields.data}
                    />
                    <Modal openModalButtonLabel={fields.openModalLabelForData}>
                      <Code>{JSON.stringify(fields.data, null, 2)}</Code>
                    </Modal>
                  </>
                );
              default:
                return (
                  <>
                    <h4>No component selected</h4>
                  </>
                );
            }
          default:
            return (
              <Columns numberOfColumns={1}>
                <h4>{fields.headline}</h4>
                {fields.assets?.length > 0 && (
                  <Columns numberOfColumns={fields.numberOfColumns}>
                    {fields.assets.map((asset, index) => {
                      return <Asset key={index} imageId={asset.sys.id} />;
                    })}
                  </Columns>
                )}
                <div className={styles.embeddedEntry}>
                  {documentToReactComponents(
                    fields.copy || fields.pageBodyCopy || fields.blogPostBody,
                    options
                  )}
                </div>
              </Columns>
            );
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollDepth =
        window.scrollY / (document.body.offsetHeight - window.innerHeight);
      const scrollDepthPercentage = Math.round(
        (isNaN(scrollDepth) ? 0 : scrollDepth) * 100
      );
      setScrollDepth(scrollDepthPercentage);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      setScrollDepth(0);
    };
  }, [setScrollDepth]);

  return (
    <>
      <Helmet>
        <title>
          {content.headline} | {process.env.REACT_APP_MY_WEBSITE_NAME}
        </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_MY_WEBSITE_URL}/post/${content.id}`}
        />
        <meta
          property="og:title"
          content={`${content.headline} | ${process.env.REACT_APP_MY_WEBSITE_NAME}`}
        />
        <meta
          name="twitter:title"
          content={`${content.headline} | ${process.env.REACT_APP_MY_WEBSITE_NAME}`}
        />
        {content.description && (
          <meta name="twitter:description" content={content.description} />
        )}
        {content.description && (
          <meta property="og:description" content={content.description} />
        )}
      </Helmet>
      <Columns numberOfColumns={1}>
        <Topper
          asset={
            !content.hideMainImageOnPage && (
              <Asset imageId={content.mainImageId} addToMeta />
            )
          }
          dateTime={content.createdAt}
          description={content.description}
          heading={content.headline}
          tags={<Tags element={Link} layout="simplified" tags={content.tags} />}
        />
        <article role="main" className={styles.contentDisplay}>
          <Columns numberOfColumns={1}>
            {documentToReactComponents(content.body, options)}
            {content.websiteUrl && (
              <Button
                buttonType="anchor"
                label="Visit Website"
                path={content.websiteUrl}
                target="_blank"
              />
            )}
            {content.otherMainImages?.length > 0 && (
              <Columns numberOfColumns={1}>
                {content.otherMainImages.map((imageId, index) => {
                  return <Asset key={index} imageId={imageId} />;
                })}
              </Columns>
            )}
            <Tags element={Link} tags={content.tags} />
          </Columns>
        </article>
      </Columns>
    </>
  );
};

export default ContentDisplay;
