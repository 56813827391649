import { action, computed, makeObservable, observable } from "mobx";
import { createClient } from "contentful";
import { getTagDescription } from "../utils";

const client = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID,
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID
});

const fetchBlogPostList = async ({
  skip = 0,
  limit = 12,
  order = "-fields.pinnedPost,-sys.createdAt",
  tags = []
}) => {
  const response = await client.getEntries({
    content_type: "blogPost",
    skip,
    limit,
    order,
    ...(tags.length > 0 && {
      "metadata.tags.sys.id[in]": tags.join(",")
    })
  });
  return response;
};

const fetchBlogPost = async (blogPostId) => {
  const response = await client.getEntry(blogPostId);
  return response;
};

const fetchTags = async () => {
  const response = await client.getTags();
  return response;
};

export class BlogPostStore {
  blogPostList = [];
  blogPostListLoading = false;
  blogPost = null;
  skip = 0;
  limit = 12;
  showMore = true;
  order = "-fields.pinnedPost,-sys.createdAt";
  tag = null;
  tags = [];

  constructor() {
    makeObservable(this, {
      blogPostList: observable,
      blogPostListLoading: observable,
      blogPost: observable,
      skip: observable,
      limit: observable,
      showMore: observable,
      fetchBlogPostList: action,
      fetchBlogPost: action,
      fetchTags: action,
      getTag: computed,
      resetBlogPost: action,
      order: observable,
      tag: observable,
      tags: observable
    });

    this.fetchTags();
  }

  fetchTags = async (tags) => {
    const response = await fetchTags();
    const data = await response;

    this.tags = data.items.map((item) => {
      return {
        id: item.sys.id,
        description: getTagDescription[item.sys.id],
        label: item.name,
        path: `/posts/${item.sys.id}`
      };
    });
  };

  get getTag() {
    if (this.tag) {
      return this.tags?.find((item) => item.id === this.tag);
    }
    return null;
  }

  fetchBlogPostList = async (tags = []) => {
    this.blogPostListLoading = this.skip === 0; // only show loading if we're on the first page

    this.tag = tags.length > 0 ? tags[0] : null;

    const response = await fetchBlogPostList({
      skip: this.skip,
      limit: this.limit,
      order: this.order,
      tags
    });

    const data = await response;

    this.skip += this.limit;
    if (data.items.length < this.limit) {
      this.showMore = false;
    }

    this.blogPostList = [
      ...this.blogPostList,
      ...data.items.map((item) => {
        const formattedDate = new Date(item.sys.createdAt).toLocaleDateString(
          "en-GB",
          {
            year: "numeric",
            month: "long",
            day: "numeric"
          }
        );
        return {
          id: item.sys.id,
          background: item.fields.pinnedPost ? "noise" : "none",
          createdAt: formattedDate,
          description: item.fields.description,
          headline: item.fields.headline,
          mainImageId: item.fields.mainImage?.sys.id,
          pinnedPost: item.fields.pinnedPost
        };
      })
    ];

    this.blogPostListLoading = false;
  };

  fetchBlogPost = async (id) => {
    const response = await fetchBlogPost(id);
    const data = await response;

    const formattedDate = new Date(data.sys.createdAt).toLocaleDateString(
      "en-GB",
      {
        year: "numeric",
        month: "long",
        day: "numeric"
      }
    );

    this.blogPost = {
      id: data.sys.id,
      headline: data.fields.headline,
      createdAt: formattedDate,
      body: data.fields.blogPostBody,
      websiteUrl: data.fields.websiteUrl,
      mainImageId: data.fields.mainImage?.sys.id,
      hideMainImageOnPage: data.fields.hideMainImageOnPage,
      otherMainImages: data.fields.otherMainImages?.map((image) => {
        return image.sys.id;
      }),
      description: data.fields.description,
      tags: data.metadata.tags.map((tag) => {
        return this.tags.find((item) => item.id === tag.sys.id);
      })
    };
  };

  resetBlogPost = () => {
    this.blogPost = null;
  };

  resetBlogPostList = () => {
    this.blogPostList = [];
    this.blogPostListLoading = true;
    this.skip = 0;
    this.showMore = true;
    this.tag = null;
  };
}
