export const getTagDescription = {
  pastProjects: "Projects I've worked on in the past",
  sideProjects: "Projects I've worked on in my spare time",
  fun: "Posts about enjoyable and entertaining things",
  wrestling: "Posts about professional wrestling",
  pitchLab: "Posts about my sports visualisation side project, PitchLab",
  weeklyNotes: "Weekly updates on what I've been up to",
  work: "Work-related posts",
  webDevelopment: "Posts about web development",
  chelseaFootballClub: "Posts about Chelsea Football Club",
  chicagoBulls: "Posts about the Chicago Bulls",
  portfolio: "Posts about my Portfolio"
};
