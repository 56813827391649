import React from "react";
import { Link } from "react-router-dom";

import { Button, Columns, Header, Icons, useNiUi } from "@namroodinc/ni-ui";

import { ReactComponent as NamroodIncIcon } from "../../icons/namroodinc.svg";
import Socials from "../footer/Socials";

import styles from "./styles.module.scss";

const SiteHeader = () => {
  const { setHamburgerOpen, theme, setTheme } = useNiUi();

  const navItem = (text, link, index) => (
    <Link key={index} to={link} onClick={() => setHamburgerOpen(false)}>
      <span>{text}</span>
    </Link>
  );

  const navItems = [
    {
      text: "About me",
      link: "/about"
    },
    {
      text: "Experience",
      link: "/experience"
    }
  ].map((item, index) => navItem(item.text, item.link, index));

  const lightDarkModeButton = (
    <Button
      icon={theme === "light" ? <Icons.CrescentIcon /> : <Icons.SunIcon />}
      label={theme === "light" ? "Dark mode" : "Light mode"}
      onClick={() => setTheme(theme === "light" ? "dark" : "light")}
      key={0}
      width="full"
    />
  );

  return (
    <Header
      logo={
        <Link
          className={styles.logo}
          to={"/"}
          onClick={() => setHamburgerOpen(false)}
        >
          <NamroodIncIcon />
        </Link>
      }
      navItems={navItems}
      controls={[lightDarkModeButton]}
      mobileMenu={
        // todo: remove mobileMenu as a prop in future
        <div className={styles.mobileMenu}>
          <Columns numberOfColumns={1}>
            <nav className={styles.mobileMenuNav}>{navItems}</nav>
            <hr />
            <Columns gap="sm" numberOfColumns={1}>
              {lightDarkModeButton}
            </Columns>
            <hr />
            <Socials />
          </Columns>
        </div>
      }
    />
  );
};

export default SiteHeader;
