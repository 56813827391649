import { createContext, useContext } from "react";
import { AssetsStore } from "./stores/AssetsStore";
import { BlogPostStore } from "./stores/BlogPostStore";
import { PageStore } from "./stores/PageStore";

export const stores = Object.freeze({
  assetsStore: new AssetsStore(),
  blogPostStore: new BlogPostStore(),
  pageStore: new PageStore()
});

export const StoresContext = createContext(stores);
export const StoresProvider = StoresContext.Provider;
export const useStore = (store) => useContext(StoresContext)[store];
