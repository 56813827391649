import { observable, action, makeObservable } from "mobx";
import { createClient } from "contentful";

const client = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID,
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID
});

const fetchAssets = async () => {
  const response = await client.getAssets();
  return response;
};

export class AssetsStore {
  assets = [];

  constructor() {
    makeObservable(this, {
      assets: observable,
      fetchAssets: action
    });

    this.fetchAssets();
  }

  fetchAssets = async () => {
    const response = await fetchAssets();
    const data = await response;

    this.assets = data.items.map((item) => {
      return {
        id: item.sys.id,
        ...item.fields
      };
    });
  };
}
