import React from "react";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import Footer from "../footer";
import Header from "../header";

import styles from "./styles.module.scss";

const Layout = observer(() => {
  return (
    <>
      <Header />
      <div className={styles.content}>
        <Outlet />
      </div>
      <Footer />
    </>
  );
});

export default Layout;
